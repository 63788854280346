@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '~booksprout-app/src/css/functions/line-height-fn' as *
@use 'src/css/functions/get-color' as *
@use '~booksprout-app/src/css/mixins/media' as media-query
@use 'src/css/mixins/button' as *
@use 'src/css/variables/media' as media
@use 'src/css/variables/z-index' as *
@use 'src/css/variables/box-shadow' as *

.bs-w-header
  align-items: center
  border-block-end: rem-fn(1) solid get-color(bs-lo)
  display: flex
  justify-content: space-between
  padding-block: rem-fn(20)
  padding-inline: rem-fn(30)

  @include media-query.from(media.$md-min)
    border-bottom: 0
    inline-size: 100%
    margin-inline: auto
    max-inline-size: rem-fn(media.$content-max-width)
    padding-block: rem-fn(45)

.bs-w-logo
  block-size: rem-fn(30)

.bs-w-btn-menu
  @include button()
  background-color: transparent
  border: none
  cursor: pointer

.bs-w-h-menu
  background-color: get-color(bs-w)
  border-bottom-left-radius: rem-fn(3)
  border-bottom-right-radius: rem-fn(3)
  inline-size: 100%
  left: 0
  padding-block: rem-fn(40)
  padding-inline: rem-fn(30)
  position: absolute
  top: rem-fn(72)
  z-index: $menu-z-index

  @include media-query.from(media.$md-min)
    padding: 0
    position: static

  nav
    display: flex
    flex-direction: column

    @include media-query.from(media.$md-min)
      align-items: center
      flex-direction: row
      column-gap: rem-fn(60)

    a
      border-block-end: rem-fn(2) solid transparent
      font-size: rem-fn(26)
      font-weight: 300
      line-height: 1
      position: relative

      &:before
        display: block
        content: attr(aria-label)
        font-weight: bold
        height: 0
        overflow: hidden
        visibility: hidden

      &:after
        block-size: rem-fn(2)
        border-block-end: rem-fn(2) solid transparent
        content: ''
        inline-size: 100%
        left: 0
        padding-block-end: rem-fn(15)
        position: absolute
        top: 100%
        transition: border-color .2s linear

      @include media-query.from(media.$md-min)
        font-size: rem-fn(14)
        font-weight: 400
        line-height: line-height(22, 14)

        &.bs-w-h-m-button
          border: 1px solid get-color(bs-o)
          border-radius: rem-fn(6)
          box-shadow: $box-shadow-base
          font-weight: 700
          // remove the gap from parent
          margin-inline-start: rem-fn(-60)
          padding: rem-fn(10) rem-fn(16)

          &__primary
            background-color: get-color(bs-g)
            border-color: transparent
            color: get-color(bs-w)

          &:last-child
            margin-inline-start: rem-fn(-34)

          &:hover,
          &:focus
            background-color: get-color(bs-w)
            border-color: get-color(bs-g)
            color: get-color(bs-b)
            text-decoration: none

        // select previous sibling of .bs-w-h-m-button
        &:has(+ .bs-w-h-m-button:not(.bs-w-h-m-button__primary))
          margin-inline-end: auto

        &:first-child
          margin-inline-start: auto

        &:after
          padding-block-end: rem-fn(6)

      &:not(:last-child)
        margin-block-end: rem-fn(40)

        @include media-query.from(media.$md-min)
          margin-block-end: rem-fn(0)

      &:hover,
      &:focus,
      &[aria-current]
        font-weight: 700
        text-decoration: none

        &:not(.bs-w-h-m-button):after
          border-block-end-color: get-color(bs-g)

.bs-w-h-menu-overlay
  background-color: rgba(0,0,0,.8)
  bottom: 0
  left: 0
  position: fixed
  right: 0
  top: rem-fn(72)
  z-index: $menu-overlay-z-index
